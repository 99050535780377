import React, { Component } from 'react'
import { Link, Route } from 'react-router-dom';
import AuthRoute from '../../Components/AuthRoute';
import service from '../../Service';
import { Menu, Dropdown, Button, Icon, message, Switch } from 'antd';


const  menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="6">
        <Icon type="clock-circle" />
        6
      </Menu.Item>
      <Menu.Item key="12">
        <Icon type="clock-circle" />
        12
      </Menu.Item>
      <Menu.Item key="18">
        <Icon type="clock-circle" />
        18
      </Menu.Item>
      <Menu.Item key="24">
        <Icon type="clock-circle" />
        24
      </Menu.Item>
    </Menu>
  );

 function handleMenuClick (e) {
    message.info('Click on menu item.');
    // console.log('click', e);
    // console.log(e.key);
    // this.state.selectedValue = e.key;
    values.selectedValue = e.key;
    // console.log(this.state.selectedValue);
    console.log(values.selectedValue);
    var postUpdateData = {
        "siteName": "Woodlands Dorm",
        "defaultPeriod" : values.selectedValue,
      };
    var postGetData = {
        "siteName": "Woodlands Dorm",
    }
    service.updateDefaultPeriod(postUpdateData)
    .then(res => {
        console.log(res);
        if (res.data.error === true) {
        message.error(res.data.message);
        return;
        }
        //success update then do a fetch
        // service.getDefaultPeriod(postGetData)
        // .then(res => {
        //     // console.log(res);
        //   if (res.data.error === true) {
        //     message.error(res.data.message);
        //     return;
        //   }
        //   console.log(res.data.resp_records[0].defaultPeriod);
        //  values.useThisValue = true;
        //  values.selectedValue = res.data.resp_records[0].defaultPeriod;
        // });
        //success update then do a refresh window
        window.location.reload(false);
    });
  };

  const values = {
    useThisValue: false,  
    currentValue: '',
    selectedValue: '',
  }

  

class ATACTSetting extends Component {
  constructor (props) {
    super(props)
    this.state = {
        currentValue: '',
        selectedValue: '',
        postData: {
            "siteName": "Woodlands Dorm"
        },
        // loading: false
        iconLoading: true,
    };
  };

  componentDidMount() {
    this.fetch();
    //this.selectedValue = '38';
  }

  fetch = (params = {}) => {
    //this.setState({ loading: true });
    service.getDefaultPeriod(this.state.postData)
    .then(res => {
        // console.log(res);
      if (res.data.error === true) {
        message.error(res.data.message);
        return;
      }
      console.log(res.data.resp_records[0].defaultPeriod);
      this.setState({
        // loading: false,
        selectedValue: res.data.resp_records[0].defaultPeriod,
        iconLoading : false,
        // pagination,
      });

    });
  };

  render () {
    return (
      <div>
        <h1>Setting of Default Visitor Pass's Valid Period: (in Hours) </h1>
        <hr/>
        <div>
        <Dropdown overlay={menu}>
            <Button loading={this.state.iconLoading}>
             {values.useThisValue ? values.selectedValue : this.state.selectedValue} <Icon type="down" />
            </Button>
        </Dropdown>
        <br />
        <br />
        <h1>Setting of Residents can Sign Up Online: </h1>
        <Switch checkedChildren="On" unCheckedChildren="Off" defaultUnChecked />
        </div>
      </div>
    )
  }
}

export default ATACTSetting