import React, { Component } from 'react'
import { Modal, Form, message } from 'antd';
import EditBLCarFrm from './EditBLCarFrm';
import store from '../../../store';
import { EditBLCarActionAsync } from '../../../Action/BLCarAction';
import { getLoginUserName, getLoginUserEmail } from '../../../Common/Auth';

const EditBLCarFrmComponent = Form.create({name: 'edit_frm'})(EditBLCarFrm);

class EditBLCar extends Component {
  editFrm = null;
  handleEditBLCar = () => {
    this.editFrm.validateFields((err, values) => {
      if(err) {
        return;
      }
      // 提交表单
      // this.props.data
      let newBLCar = {...this.props.data, ...values};
      // newUser.userRole = "SiteAdmin";
      // newUser.siteName = "Woodlands Dorm";
      // newUser.createdBy = "OG";
      console.log("got id or not!");
      console.log(this.props.data);
      console.log(newBLCar);
      getLoginUserEmail()
        .then(res => {
          //Get Current Date and Time
          var today = new Date();
          var dd = today.getDate();
          var mm = today.getMonth()+1; 
          var yyyy = today.getFullYear();
          if(dd<10) 
          {
              dd='0'+dd;
          }
          if(mm<10) 
          {
              mm='0'+mm;
          }

          var hours = today.getHours();
          var minutes = today.getMinutes();
          var ampm = hours >= 12 ? 'pm' : 'am';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0'+minutes : minutes;
          var strTime = hours + ':' + minutes + ' ' + ampm;
          
          today = yyyy + mm + dd + ' ' + strTime
          newBLCar.logs = "\n" + "Updated with reason: " + values.reason + " on " + today + ' | by ' + res;
        })
      store
        .dispatch(EditBLCarActionAsync(newBLCar))
        .then(res => {
          // console.log(res.data);
          // if (res["error"]) {
          //   console.log(res.message);
          // }else {
          //   console.log(res.resp_records);
          // }
          
          message.info('Update Successfully!');
          this.props.close();
        })
        .catch((err) => {
          console.log(err);
          message.error('Update Failed!');
        })
    })
  }
  render () {
    return (
      <Modal
        destroyOnClose
        title="Update Car Black List"
        visible={this.props.visible}
        okText="Update"
        cancelText="Cancel"
        onCancel={()=> {this.props.close()}}
        onOk={this.handleEditBLCar}
      >
        <EditBLCarFrmComponent ref={frm => this.editFrm = frm} data={this.props.data}></EditBLCarFrmComponent>
      </Modal>
    )
  }
}

export default EditBLCar