import React, { Component } from 'react'
import { Link, Route, Switch } from 'react-router-dom';
import AuthRoute from '../../Components/AuthRoute';
import { Table, Tag, DatePicker, Radio } from 'antd';
import service from '../../Service';
import { message } from 'antd';
import { CSVLink } from "react-csv";
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const todayDate = new Date().toISOString().slice(0,10).replace(/-/g, "");
const columns = [
    {
        title: 'Visitor Name',
        dataIndex: 'visitorName',
        // sorter: true,
        width: '15%',
        sorter: (a, b) => (a.visitorName < b.visitorName ? -1 : a.visitorName > b.visitorName ? 1 : 0),//a.visitorName.attr > b.visitorName.attr,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Visitor Phone No.',
        dataIndex: 'visitorPhone',
        //   filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
        width: '15%',
    },
    {
        title: 'Resident Name',
        dataIndex: 'hostName',
    },
    {
        title: 'Resident Unit',
        dataIndex: 'hostUnit',
    },{
        title: 'Reocrds',
        dataIndex: 'records',
        width: 235,
    },
    // {
    //     title: 'Date In/ Out',
    //     sorter: (a, b) => a.lastStatusDate > b.lastStatusDate,
    //     dataIndex: 'lastStatusDate',
    //     filters: [
    //         {
    //           text: '20191117',
    //           value: '20191117',
    //         },
    //         {
    //           text: '20191113',
    //           value: '20191113',
    //         },
    //       ],
    //     filterMultiple: false,
    //     onFilter: (value, record) => record.dateCreated.indexOf(value) === 0,
    // },
    // {
    //     title: 'Time In/Out',
    //     sorter: (a, b) => a.lastStatusTime > b.lastStatusTime,
    //     dataIndex: 'lastStatusTime',
    // },
    {
        title: 'Last Staus',
        sorter: (a, b) => a.lastStatus > b.lastStatus,
        dataIndex: 'lastStatus',
    },
    {
        title: 'Tag',
        dataIndex: 'tag',
        render: (text, record) => (
          <span>
          <Tag color= {record.tag == "Completed" ? "green":"red"}>{record.tag}</Tag>
          </span>
      ),
      // filters: [
      //   {
      //     text: 'Completed',
      //     value: 'Completed',
      //   },
      //   {
      //     text: 'InCompleted',
      //     value: 'InCompleted',
      //   },
      //   {
      //     text: 'Double Entry',
      //     value: 'Double Entry',
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => record.tag === value,
      // sorter: (a, b) => a.tag.length - b.tag.length,
      // sortDirections: ['descend', 'ascend'],
    }
  ];
  const postData = {
      "siteName": "Woodlands Dorm"
  }
  const emptyData = [{
    "visitorName" : "",
    "visitorPhone" : "",
    "hostName" : "",
    "hostUnit" : "",
    "records" : "",
    "lastStatus" : "",
    "tag" : ""
  }]
class VisitorEntry extends Component {
    intervalID = 0;
    state = {
        data: [],
        filteredDate:[],
        pagination: {},
        loading: false,
        postData: {
            "siteName": "Woodlands Dorm"
        },
        headers : [
          { label: "Visitor Name", key: "visitorName" },
          { label: "Visitor Phone No.", key: "visitorPhone" },
          { label: "Resident Name", key: "hostName" },
          { label: "Resident Unit", key: "hostUnit" },
          { label: "Reocrds", key: "records" },
          { label: "Last Staus", key: "lastStatus" },
          { label: "Tag", key: "tag" }
        ],
        dataFilter: "All",
        filename: "Woodlands_Dorm_LiveVisitor_Records.csv",
        isDateFilterOn: false,
    };
    componentDidMount() {
        // this.fetch();
        // this.intervalID = setInterval(this.fetch, 10000);
        // this.fetchWithDates(todayDate, todayDate);
        this.intervalID = setInterval(this.fetchWithDates(todayDate, todayDate), 10000);
        console.log(todayDate);
    }
    componentWillUnmount() {
      clearInterval(this.intervalID);
    }
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
        });
        this.fetch({
          results: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
      };
    handleDataFilterChange = e => {
      this.setState({ dataFilter: e.target.value });
      var theFilteredData = [];
      if (e.target.value === "All"){
        if (this.state.isDateFilterOn){
          theFilteredData = this.state.data;
        }else {
          // this.fetch();
          // this.intervalID = setInterval(this.fetch, 10000);
          this.intervalID = setInterval(this.fetchWithDates(todayDate, todayDate), 10000);
        }
       
        this.setState({filename: "Woodlands_Dorm_LiveVisitor_Records.csv"});
      }else {
        clearInterval(this.intervalID);
        theFilteredData = this.state.data.filter(function(theData) {
          return theData.tag == e.target.value;
        });
        var newFileName = "Woodlands_Dorm_LiveVisitor_Records_" + e.target.value + ".csv";
        this.setState({filename: newFileName});
      }
      
      this.setState({ filteredData: theFilteredData});
    };
    handleDatePickerChange = (dates, dateStrings) => {
      // console.log(dates[0].toISOString().slice(0,10)); //2020-05-01
      // console.log(dates[1].toISOString().slice(0,10)); //2020-05-31
      
      // console.log(dateStrings[0]); //2020-05-01
      // console.log(dateStrings[1]); //2020-05-31
      var date1 = dateStrings[0];
      var date2 = dateStrings[1];
      date1 = date1.replace(/-/g, "");//20200501
      date2 = date2.replace(/-/g,"");//20200531
      clearInterval(this.intervalID);
      this.fetchWithDates(date1, date2);
      this.setState({isDateFilterOn: true});
    };  
    fetch = (params = {}) => {
      this.setState({ loading: true });
      service.loadVisitorEntryRecords(this.state.postData)
      .then(res => {
        if (res.data.error === true) {
          message.error(res.data.message);
        }
        console.log(res.data.resp_records);
        const pagination = { ...this.state.pagination };
          //Read total count from server
          if (res.data.resp_records == undefined){
            pagination.total = 0;
          }else {
            pagination.total = res.data.resp_records.count;
          }
        
        this.setState({
          loading: false,
          data: res.data.resp_records,
          filteredData: res.data.resp_records,
          pagination,
        });
      });
    };
    fetchWithDates = (date1, date2) => {
      this.setState({ loading: true });
      var paramsToPass = {
        siteName: "Woodlands Dorm",
        startDate: date1,
        endDate: date2
      };
      console.log(paramsToPass);
      service.loadVisitorEntryRecordsWithDates(paramsToPass)
      .then(res => {
        const pagination = { ...this.state.pagination };
          //Read total count from server
          if (res.data.resp_records == undefined){
            pagination.total = 0;
            this.setState({
              loading: false,
              data: emptyData,
              filteredData: emptyData,
              pagination,
            });
            return;
          }else {
            pagination.total = res.data.resp_records.count;
          }

        if (res.data.error === true) {
          message.error(res.data.message);
          this.setState({
            loading: false,
            data: emptyData,
            filteredData: emptyData,
            pagination,
          });
          return;
        }
        console.log(res.data.resp_records);

        this.setState({
          loading: false,
          data: res.data.resp_records,
          filteredData: res.data.resp_records,
          pagination,
        });
      });
    };
  render () {
    const { dataFilter, filename } = this.state;
   
    return (
      <div>
      <div>
        <CSVLink data={this.state.data} headers={this.state.headers} filename={filename}>
        Press Here to download the report
        </CSVLink>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p className="active">Apply filters below:</p>
      </div>
      <div>
      <RangePicker 
       format="YYYY-MM-DD"
       showToday = {true}
       defaultValue = {[moment(), moment()]}
       format={dateFormat}
       onChange={this.handleDatePickerChange}
      /> 
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Radio.Group value={dataFilter} onChange={this.handleDataFilterChange}>
          <Radio.Button value="All">All</Radio.Button>
          <Radio.Button value="Completed">Completed</Radio.Button>
          <Radio.Button value="InCompleted">InCompleted</Radio.Button>
          <Radio.Button value="Double Entry">Double Entry</Radio.Button>
      </Radio.Group>
      <br />
      </div>
      <Table rowKey="uid"
        columns={columns}
        rowKey={record => record.id}
        dataSource={this.state.filteredData}
        pagination={{ pageSize: 120 }}//{this.state.pagination}
        loading={this.state.loading}
        onChange={this.handleTableChange}
      />
     </div>
    );
  }
}
export default VisitorEntry
