import React, { Component } from 'react'
import { Link, Route, Switch } from 'react-router-dom';
import AuthRoute from '../../Components/AuthRoute';
import { Table } from 'antd';
import service from '../../Service';
import { message } from 'antd';

const columns = [
    {
        title: 'Visitor Name',
        dataIndex: 'name',
        // sorter: true,
        width: '20%',
        sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)//a.name.attr > b.name.attr,
    },
    {
        title: 'Phone',
        dataIndex: 'contactNo',
        //   filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
        width: '20%',
    },
    {
        title: 'Resident Name',
        dataIndex: 'residentName',
    },
    {
        title: 'Guard Name',
        dataIndex: 'guardName',
    },
    {
        title: 'Date',
        sorter: (a, b) => a.dateCreated > b.dateCreated,
        dataIndex: 'dateCreated',
    },
    {
        title: 'Time',
        sorter: (a, b) => a.timeCreated > b.timeCreated,
        dataIndex: 'timeCreated',
    }
  ];
  const postData = {
      "siteName": "Woodlands Dorm"
  }
class GuardVisitor extends Component {
    intervalID = 0;
    state = {
        data: [],
        pagination: {},
        loading: false,
        postData: {
            "siteName": "Woodlands Dorm"
        }
    };
    componentDidMount() {
      this.fetch();
      this.intervalID = setInterval(this.fetch, 10000);
    }
    componentWillUnmount() {
      clearInterval(this.intervalID);
    }
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
        });
        this.fetch({
          results: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
      };
      fetch = (params = {}) => {
        this.setState({ loading: true });
        service.loadGuardVisitors(this.state.postData)
        .then(res => {
          if (res.data.error === true) {
            message.error(res.data.message);
          }
          const pagination = { ...this.state.pagination };
            //Read total count from server
            if (res.data.resp_records == undefined){
              pagination.total = 0;
            }else {
              pagination.total = res.data.resp_records.count;
            }
          this.setState({
            loading: false,
            data: res.data.resp_records,
            pagination,
          });
        });
      };
  render () {
    return (
      <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={this.state.data}
        pagination={this.state.pagination}
        loading={this.state.loading}
        onChange={this.handleTableChange}
      />
    );
  }
}
export default GuardVisitor
