import React, { Component } from 'react'
import { Breadcrumb, Table, Button, Modal, message, Avatar, Popconfirm, Input } from 'antd';
import { Link } from 'react-router-dom';
import { LoadUserActionAsync } from '../../../Action/UserAction';
import { LoadSuperAdminUserActionAsync } from '../../../Action/UserAction';
import EditUser from './EditUser';
import service from '../../../Service';
import store from '../../../store';
import { getLoginUserEmail } from '../../../Common/Auth';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';

class ProfileMgr extends Component {
  state = {
    showSetRoleDialog: false,  // 显示设置用户角色对话框
    showSetPerDialog: false,   // 显示设置用户权限对话框
    showAddUserDialog: false,  // 显示要添加用户的对话框
    showEditUserDialog: false, // 显示修改的对话框
    editUserRow: null,         // 当前编辑的用户信息 
    setRoleUser: null,         // 当前设置角色的用户
    setPerUser: null,          // 当前设置权限的用户数据
    unsubscribe: null,
    selectRowKeys: [],
    userlist: store.getState().UserList.list,
    total: 0,
    params: {_page: 1, _limit: 6, siteName: "Woodlands Dorm"},
    data: {userEmail: "", siteName: "Woodlands Dorm"},
    columns: [{
      key: 'id',
      title: 'Id',
      dataIndex: 'id'
    },{
      key: 'userEmail',
      title: 'Email',
      dataIndex: 'userEmail'
    },{
      key: 'userName',
      title: 'User Name',
      dataIndex: 'userName'
    }, {
      key: 'userPhone',
      title: 'Phone Number',
      dataIndex: 'userPhone'
    }, {
      key: 'userRole',
      title: 'My Role',
      dataIndex: 'userRole'
    }, /*{
      key: 'del',
      title: 'Edit',
      dataIndex: 'del',
      render: (del, row) => {
        return (
          <div>
            <Button 
              onClick={()=> this.setState({showEditUserDialog: true, editUserRow: row})}
              style={{marginRight: '5px'}} type="primary"
            >
              Edit
            </Button>
            <Popconfirm
              onConfirm={ () => {
                // message.info(row.id);
                this.deleteUser(row.id);
              }}
              okText="确认"
              cancelText="取消"
              title="您确认要删除吗？"
            >
              <Button type="danger" >
                Delete
              </Button>
            </Popconfirm>
          </div>
        );
      }
    }*/]
  }
  userListChange = () => {
    const UserList = store.getState().UserList;
    this.setState({userlist: UserList.list, total: UserList.total});
  }
  componentDidMount() {
    // 发送ajax请求到后台，获取当前用户的列表数据
    // service.loadUserList()
    // .then(res => {
    //   this.setState({userlist: res.data});
    // })
    let userEmail = GetLoginUserInfo().userEmail;
    store.dispatch(LoadSuperAdminUserActionAsync({"userEmail":userEmail, "siteName":"Woodlands Dorm"}));
    const unsubscribe = store.subscribe(this.userListChange);
    this.setState({unsubscribe: unsubscribe});
    let userId = GetLoginUserInfo().id;
    // this.setState({selectRowKeys: [userId]});
  }

  componentWillUnmount() {
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  changePage = (page, pageSize, q="") => {
    if(!!q) {
      q = this.state.params.q;
    }
    this.setState(preState=> {
      return {...preState, ...{params: {_page: page, _limit: pageSize, q}}}
    }, ()=> {
      store.dispatch(LoadSuperAdminUserActionAsync(this.state.params));
    });
  }

  hideAddUserDialog = () => {
    this.setState({showAddUserDialog: false});
  }

  hideEditUserDialog = () => {
    this.setState({showEditUserDialog: false});
  }
  hideSetRoleDialog = () => {
    this.setState({showSetRoleDialog: false});
  }
  hideSetPerDialog = () => {
    this.setState({showSetPerDialog: false});
  }

  handleEdit = () => {
    if(this.state.selectRowKeys.length !== 1) {
      message.error('Please select one to edit!');
      return;
    }

    // 拿到要进行编辑的数据
    const userId = this.state.selectRowKeys[0]
    let editUser = store.getState().UserList.list.find(item => item.id === userId);
    console.log(editUser);
    this.setState({
      showEditUserDialog: true,
      editUserRow: editUser
    })
  }
  buttonStyle = {margin: '5px'};

  render () {
    let { selectRowKeys } = this.state;
    let userRowSelection = {
      selectedRowKeys: selectRowKeys,
      onChange: (selectedRowKeys) => {
        console.log(selectedRowKeys);
        this.setState({selectRowKeys: selectedRowKeys})
      }
    }
    return (
      <div className="admin-usermgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/profile_mgr">Profile</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        <Button onClick={ this.handleEdit } style={this.buttonStyle} type="primary">Update</Button>
        <Table
          bordered
          style={{backgroundColor: '#FEFEFE'}}
          dataSource={this.state.userlist}
          columns={this.state.columns}
          rowSelection={userRowSelection}
          rowKey="id"
          pagination = {{total: this.state.total, pageSize: 6, defaultCurrent: 1, onChange: this.changePage}}
        ></Table>
     
        <EditUser data={this.state.editUserRow} close={this.hideEditUserDialog} visible={this.state.showEditUserDialog}></EditUser>
      </div>
    )
  }
}

export default ProfileMgr